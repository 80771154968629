





















































































import { imageData } from '@/utils/utilsData';
import API from '@/plugins/axios';
import Vue from 'vue';
export default Vue.extend({
  data: () => ({
    showDialog: false,
    imageData: { ...imageData },
    data: {
      id: '',
    },
    fileData: {},
  }),
  methods: {
    async deleteSelected() {
      const { status } = await API.delete(`/administration/units/${this.data.id}/`);
      if (status == 200) {
        await this.$router.push(`/admin/`);
      }
    },
  },
  async mounted() {
    const { data } = await API.get(`/administration/units/${this.$route.params.id}/`);
    this.data = data;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    if ((this.data as any).file_uuid) {
      const { data } = await API.get(`/pdf-file/${(this.data as any).file_uuid}/`, {
        baseURL: process.env.VUE_APP_API_URL_FILES,
      });
      this.fileData = data;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
  },
});
